import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter } from '@core/router';

import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import { isValidSportEventsPageIdentifier } from 'web/src/modules/sportline/guards';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import {
  resolveRegionPageLink,
} from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

interface UseBaseSportEventsRegionPageNavigationGuardComposable {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AbstractPrefetchResult;
  getRedirectLocation(
    router: AppVueRouter,
    currentLocation: RouteLocationNormalized,
  ): Maybe<RouteLocationRaw>;
}

export function useBaseSportEventsRegionPageNavigationGuard(): UseBaseSportEventsRegionPageNavigationGuardComposable {
  const regionPageStore = useRegionPageStore();
  const region = toRef(regionPageStore, 'region');
  function prefetch(
    _: AppVueRouter,
    to: RouteLocationNormalized,
  ) {
    const navigationParameters: SportEventsPageIdentifier = getSportlinePageNavigationParameters(to);

    if (!isValidSportEventsPageIdentifier(navigationParameters)) {
      return { name: RouteName.ERROR_NOT_FOUND_404 };
    }

    useRegionPageStore().setPageIdentifier(navigationParameters);
    useSportlineBreadcrumbsStore().setPageIdentifier(navigationParameters);
  }

  function getRedirectLocation(
    router: AppVueRouter,
    currentLocation: RouteLocationNormalized,
  ): Maybe<RouteLocationRaw> {
    const target = region.value?.navigationParameters;

    if (!target) {
      return null;
    }

    const currentTarget = getSportlinePageNavigationParameters(currentLocation);

    if (currentTarget.regionId !== target.id) {
      return null;
    }

    if (
      currentTarget.regionUrlName === target.urlName
      && currentTarget.sportFamily === target.sportFamily
    ) {
      return null;
    }

    return router.resolve301location(resolveRegionPageLink(target));
  }

  return {
    prefetch,
    getRedirectLocation,
  };
}
