import type { Ref } from 'vue';
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import type {
  SportEventsRegionSeoMetaInfo,
} from 'web/src/modules/sportline/submodules/region/types';
import type {
  OutrightSportlineEvent,
  Region,
  Sport,
  SportElement,
  SportEventSeoMetaInfo,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';
import { getSportEventSeoMetaInfo } from 'web/src/modules/sportline/utils';

interface UseRegionRoutePageComposable {
  isReady: Ref<boolean>;
  isLoadingIndicatorShown: Ref<boolean>;
  isMarketTypesSelectionEnabled: Ref<boolean>;
  isContainEvents: Ref<boolean>;
  sport: Ref<Maybe<Sport>>;
  region: Ref<Maybe<Region>>;
  sportElementsList: Ref<Maybe<SportElement<SportlineEvent>[]>>;
  outrightEvents: Ref<SportElement<OutrightSportlineEvent>[]>;
  metaParameters: Ref<SportEventsRegionSeoMetaInfo>;
  activatePageHook(isMount: boolean): void;
  deactivatePageHook(isUnmount: boolean): void;
}

export function useRegionRoutePage(): UseRegionRoutePageComposable {
  const router = useRouter();
  const regionPageStore = useRegionPageStore();
  const {
    initialRequests: initFavoriteSportEvents,
  } = useFavoritesEventsStore();

  const isReady = toRef(regionPageStore, 'isReady');
  const isLoadingIndicatorShown = toRef(regionPageStore, 'isLoadingIndicatorShown');
  const isMarketTypesSelectionEnabled = toRef(regionPageStore, 'isMarketTypesSelectionEnabled');
  const isContainEvents = toRef(regionPageStore, 'containEvents');
  const sport = toRef(regionPageStore, 'sport');
  const region = toRef(regionPageStore, 'region');
  const sportElementsList = toRef(regionPageStore, 'sportElementsList');
  const outrightEvents = toRef(regionPageStore, 'outrightEvents');

  const metaParameters = computed<SportEventsRegionSeoMetaInfo>(() => {
    if (!isReady.value) {
      return {};
    }

    const sportEvents: Optional<SportEventSeoMetaInfo[]> = sportElementsList
      .value
      ?.flatMap((sportElement: SportElement) => sportElement
        .regions
        .flatMap((regionElement) => regionElement
          .leagues
          .flatMap((leagueElement) => leagueElement
            .sportEvents
            .map((sportEventElement) => getSportEventSeoMetaInfo({
              sportEvent: sportEventElement.sportEvent,
              sport: sportElement.sport,
              region: regionElement.region,
              league: leagueElement.league,
              vm: { $router: router },
            }) as SportEventSeoMetaInfo))));

    return {
      sport: sport.value?.name || '',
      sportFamily: sport.value?.navigationParameters.urlName || '',
      sportEmoji: sport.value?.representation.emoji || '',
      region: region.value?.name || '',
      sportEvents: {
        value: sportEvents,
        merge(oldValue: SportEventSeoMetaInfo[], newValue: SportEventSeoMetaInfo[]) {
          return [...oldValue, ...newValue];
        },
      },
    };
  });

  function activatePageHook(isMount: boolean): void {
    if (isMount) {
      void initFavoriteSportEvents();
    }

    void regionPageStore.setBackgroundUpdateEnabled(true);
  }

  function deactivatePageHook(isUnmount: boolean): void {
    if (isUnmount) {
      regionPageStore.clearRegion();
    }

    void regionPageStore.setBackgroundUpdateEnabled(false);
    // clear event info after leave page (need for leave page watch in store)
    regionPageStore.setPageIdentifier({ sportFamily: '' });
  }

  return {
    isReady,
    isLoadingIndicatorShown,
    isMarketTypesSelectionEnabled,
    isContainEvents,
    sport,
    region,
    sportElementsList,
    outrightEvents,
    metaParameters,
    activatePageHook,
    deactivatePageHook,
  };
}
