import type {
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';
import { toRef } from 'vue';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { useRootNavigationStore } from '@core/navigation';

import type { ConfigForLeagueLoading } from 'web/src/modules/sportline/submodules/league/types';
import type { LoadRegionConfig } from 'web/src/modules/sportline/submodules/region/types';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useRedirectBySportsTree } from 'web/src/modules/sportline/submodules/redirects/composables';
import { useLoadRegionConfig } from 'web/src/modules/sportline/submodules/region/composables';
import { getIsBrokenUrl } from 'web/src/modules/sportline/submodules/region/services/navigation/utils';
import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

import { useBaseSportEventsRegionPageNavigationGuard } from './useBaseSportEventsRegionPageNavigationGuard';
import { useRegionNavigationBackButton } from './useRegionNavigationBackButton';

interface UseDefaultSportEventsRegionPageNavigationGuardComposable {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AbstractPrefetchResult;
}

export function useDefaultSportEventsRegionPageNavigationGuard(): UseDefaultSportEventsRegionPageNavigationGuardComposable {
  const regionPageStore = useRegionPageStore();
  const routeNavigationStore = useRootNavigationStore();
  const navigationStore = useSportlineNavigationStore();
  const isReady = toRef(regionPageStore, 'isReady');
  const currentRegionLoadingConfigId = toRef(regionPageStore, 'currentRegionLoadingConfigId');
  const baseRegionNavigationGuard = useBaseSportEventsRegionPageNavigationGuard();
  const sportlineNavigationBetweenPagesFilter = toRef(navigationStore, 'sportlineNavigationBetweenPagesFilter');

  const {
    getRedirectLocation,
    prefetch: basePrefetch,
  } = baseRegionNavigationGuard;

  const { setOnlyFirstRouteContentLoading, setRouteContentLoaded } = routeNavigationStore;
  const { activateBackButton } = useRegionNavigationBackButton();
  const {
    setConfigForRegionLoading,
    initialRequests,
    clearRegion,
    syncBackgroundRequests,
    awaitStateIsLoaded,
  } = regionPageStore;

  async function loadRegionForConfig(config: LoadRegionConfig, routeName: BaseRouteNameType): Promise<void> {
    setOnlyFirstRouteContentLoading(routeName);
    setConfigForRegionLoading(config);
    await initialRequests();
    setRouteContentLoaded(routeName);
  }

  function handleRedirectForWrongParameters(router: AppVueRouter, to: RouteLocationNormalized): void {
    const redirectTo = getRedirectLocation(router, to);
    if (redirectTo) {
      void router.pushRedirect(redirectTo, 301);
    }
  }

  async function handleRedirectsForEmptyPage(router: AppVueRouter, to: RouteLocationNormalized): Promise<void> {
    await awaitStateIsLoaded();

    if (isReady.value) {
      handleRedirectForWrongParameters(router, to);
      return;
    }

    const redirect = useRedirectBySportsTree({
      router,
      code: 301,
      allowedTargets: {
        sport: true,
        region: true,
      },
    });

    void redirect.make();

    void regionPageStore.awaitLeavePage().then(() => {
      redirect.clearTimeout();
    });
  }

  function syncRegionForConfig(config: ConfigForLeagueLoading, routeName: BaseRouteNameType): void {
    if (config.filter) {
      clearRegion();
    }

    void syncBackgroundRequests({});
    setRouteContentLoaded(routeName);
  }

  async function prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const superResult = await basePrefetch(router, to, from);
    const { regionConfig } = useLoadRegionConfig({
      to: toRef(to),
      sportlineNavigationBetweenPagesFilter,
    });

    navigationStore.setSportlineNavigationBetweenPagesFilter(null);

    if (superResult !== undefined) {
      return (superResult as RouteLocationRaw);
    }

    if (getIsBrokenUrl(to)) {
      void (useRedirectBySportsTree({
        router,
        code: 301,
        allowedTargets: {
          region: true,
        },
      })).redirect();
      return;
    }

    const navigationParameters = getSportlinePageNavigationParameters(to);

    const isLoadingNewRegion = !currentRegionLoadingConfigId.value
      || navigationParameters.regionId !== currentRegionLoadingConfigId.value;

    activateBackButton(navigationParameters);

    const { setPersistLocation } = useSportlinePersistLocation();
    setPersistLocation('sports', navigationParameters, 'region');

    if (!isReady.value || isLoadingNewRegion) {
      void loadRegionForConfig(regionConfig.value, RouteName.SPORT_REGION);
      void handleRedirectsForEmptyPage(router, to);
    } else {
      syncRegionForConfig(regionConfig.value, RouteName.SPORT_REGION);
      handleRedirectForWrongParameters(router, to);
    }
  }

  return { prefetch };
}
