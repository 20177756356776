import type { RouteLocationNormalized } from 'vue-router';

import type { AbstractPrefetchResult, AppVueRouter } from '@core/router';
import { AbstractPrefetch } from '@core/router';

import {
  useSportlineRegionPageNavigationService,
} from 'web/src/modules/sportline/submodules/region/services/navigation/useSportlineRegionPageNavigationService';

export default class RegionPagePrefetch extends AbstractPrefetch {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AbstractPrefetchResult {
    return useSportlineRegionPageNavigationService().prefetch(router, to, from);
  }
}
