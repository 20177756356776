import type { Ref } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';
import { computed } from 'vue';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { LoadRegionConfig } from 'web/src/modules/sportline/submodules/region/types';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';

interface LoadRegionConfigProps {
  to: Ref<RouteLocationNormalized>;
  sportlineNavigationBetweenPagesFilter: Ref<SportDateTimeFilter | null>;
}

interface LoadRegionConfigComposable {
  regionConfig: Ref<LoadRegionConfig>;
}

export function useLoadRegionConfig(
  props: LoadRegionConfigProps,
): LoadRegionConfigComposable {
  const { to, sportlineNavigationBetweenPagesFilter } = props;
  const navigationParameters = computed<SportEventsPageIdentifier>(() => getSportPageNavigationParameters(to.value));
  const filter = computed<SportDateTimeFilter | undefined>(() => (sportlineNavigationBetweenPagesFilter.value ?? undefined));
  const regionConfig = computed<LoadRegionConfig>(() => ({ id: navigationParameters.value.regionId, filter: filter.value }));

  return {
    regionConfig,
  };
}
