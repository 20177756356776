<script lang="ts" setup>
import LeagueBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/LeagueBreadcrumbsItem.vue';
import RegionBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/RegionBreadcrumbsItem.vue';
import SportBreadcrumbsItem from 'web/src/modules/sportline/submodules/breadcrumbs/components/SportBreadcrumbsItem.vue';
import {
  useActiveBreadcrumb,
  useSportlineBreadcrumbsLeagueItem,
  useSportlineBreadcrumbsMetaParameters,
  useSportlineBreadcrumbsRegionItem,
  useSportlineBreadcrumbsSportItem,
} from 'web/src/modules/sportline/submodules/breadcrumbs/composables';
import { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';

interface RegionBreadcrumbsProps {
  defaultRegionTitle?: string;
}

defineProps<RegionBreadcrumbsProps>();

const {
  activeId,
  toggleId,
} = useActiveBreadcrumb();
const {
  sportTreeElement,
  title: sportItemTitle,
  isShown: isSportItemShown,
  pageLocation: sportPageLocation,
  sportRepresentationFamily,
} = useSportlineBreadcrumbsSportItem({});
const {
  regionElement,
  relatedRegions,
  title: regionItemTitle,
  isShown: isRegionItemShown,
  pageLocation: regionPageLocation,
} = useSportlineBreadcrumbsRegionItem({});
const {
  leagueElement,
  relatedLeagues,
  title: leagueItemTitle,
  isShown: isLeagueItemShown,
  pageLocation: leaguePageLocation,
} = useSportlineBreadcrumbsLeagueItem({});

useSportlineBreadcrumbsMetaParameters({
  sportElement: sportTreeElement,
  sportPageLocation,
  regionElement,
  regionPageLocation,
  leagueElement,
  leaguePageLocation,
});
</script>

<template>
  <div v-auto-id="'RegionBreadcrumbs'">
    <div :class="$style['sportline-breadcrumbs__inner']">
      <ol :class="$style['sportline-breadcrumbs__content']">
        <SportBreadcrumbsItem
          v-if="isSportItemShown"
          :key="sportTreeElement ? sportTreeElement.sport.id : 'unselectedSport'"
          :title="sportItemTitle"
          :to="sportPageLocation"
          :position="1"
          :sport-family="sportRepresentationFamily"
          is-menu
        />

        <RegionBreadcrumbsItem
          v-if="isRegionItemShown"
          :key="regionElement?.region.id ?? 'unselectedRegion'"
          :active-region-id="regionElement?.region.id"
          :related-regions="relatedRegions"
          :title="defaultRegionTitle || regionItemTitle"
          :to="regionPageLocation"
          :position="2"
          :open="activeId === BreadcrumbId.Regions"
          @toggle="toggleId"
        />

        <LeagueBreadcrumbsItem
          v-if="isLeagueItemShown"
          :key="leagueElement ? leagueElement.league.id : 'unselectedLeague'"
          :league-element="leagueElement"
          :related-leagues="relatedLeagues"
          :title="leagueItemTitle"
          :to="leaguePageLocation"
          :position="3"
          :open="activeId === BreadcrumbId.Leagues"
          @toggle="toggleId"
        />
      </ol>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/submodules/breadcrumbs/styles/sportline-breadcrumbs';
</style>
