<script setup lang="ts">
import {
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
} from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import SlicedTreeNavigation from 'web/src/modules/sportline/submodules/navigation/views/bet-swipe-navigation/SlicedTreeNavigation.vue';
import RegionTitleBar from 'web/src/modules/sportline/submodules/region/components/RegionTitleBar.vue';
import RegionBreadcrumbs from 'web/src/modules/sportline/submodules/region/views/RegionBreadcrumbs.vue';

import BetSwipeEventsList from './bet-swipe/RegionPageEventsList.vue';
import DefaultEventsList from './default/RegionPageEventsList.vue';
import { useRegionRoutePage } from './useRegionRoutePage';

const {
  isBetSwipeNavigationLayoutEnabled,
  isBetSwipeRegionLayoutEnabled,
} = useSportlineLayoutSettings();
const {
  isReady,
  isLoadingIndicatorShown,
  isMarketTypesSelectionEnabled,
  isContainEvents,
  sportElementsList,
  sport,
  region,
  outrightEvents,
  metaParameters,
  activatePageHook,
  deactivatePageHook,
} = useRegionRoutePage();
useSeoMetaParameters(metaParameters);

onMounted(() => activatePageHook(true));
onActivated(() => activatePageHook(false));
onUnmounted(() => deactivatePageHook(true));
onDeactivated(() => deactivatePageHook(false));

useKeepAlive([
  RouteName.HOME,
  RouteName.SPORTLINE_LIVE_EVENTS,
  RouteName.SPORTLINE_PREMATCH_EVENTS,
  RouteName.SPORT_REGION,
  RouteName.SPORT_LEAGUE,
  RouteName.SPORT_EVENT_DETAILS,
  RouteName.SPORTLINE_CYBERSPORT,
]);
</script>

<template>
  <div v-auto-id="'RegionRoutePage'" class="component-wrapper">
    <div
      :class="{
        [$style['sport-event-region']]: true,
        [$style['sport-event-region--extra-paddings']]: null && isBetSwipeNavigationLayoutEnabled,
      }"
    >
      <VLoaderMaskedDelayed
        v-if="!isReady || isLoadingIndicatorShown"
      />

      <template v-if="isReady">
        <template v-if="null && isBetSwipeNavigationLayoutEnabled">
          <SlicedTreeNavigation :class="$style['sport-event-region__navigation']" />
        </template>
        <template v-else>
          <RegionBreadcrumbs
            v-if="'1'"
            :default-region-title="region?.name"
          />
          <RegionTitleBar
            v-else-if="sport && region"
            :region="region.name"
            :sport="sport.name"
          />
        </template>

        <template v-if="isContainEvents">
          <BetSwipeEventsList
            v-if="null && isBetSwipeRegionLayoutEnabled"
            :region="region"
            :sport-elements-list="sportElementsList"
            :outright-events="outrightEvents"
          />
          <DefaultEventsList
            v-else
            :sport-elements-list="sportElementsList"
            :outright-events="outrightEvents"
            :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
          />
        </template>
        <div v-else>
          <div :class="$style['no-events-content']">
            <div :class="$style['no-events-content__label']">
              {{ $t('JS_EVENTS_NOT_FOUND') }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/no-events';
@import 'web/src/modules/sportline/styles/sportline-page';

@include for-layout using ($isDesktop) {
  .sport-event-region {
    position: relative;
    padding: if($isDesktop, 0, 0 8px);

    &--extra-paddings {
      padding: if($isDesktop, 0, 0 16px);
    }

    &__navigation {
      @include sportline-page-full-width-block($isDesktop);

      margin-bottom: if($isDesktop, 16px, 0);
    }
  }
}
</style>
