import type {
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter } from '@core/router';

import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { useLoadRegionConfig } from 'web/src/modules/sportline/submodules/region/composables';
import {
  useBaseSportEventsRegionPageNavigationGuard,
} from 'web/src/modules/sportline/submodules/region/services/navigation/useBaseSportEventsRegionPageNavigationGuard';
import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';

import { getIsBrokenUrl } from './utils/getIsBrokenUrl';

interface UsePrerenderSportEventsRegionPageNavigationGuardComposable {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AbstractPrefetchResult;
}

export function usePrerenderSportEventsRegionPageNavigationGuard(): UsePrerenderSportEventsRegionPageNavigationGuardComposable {
  const regionPageStore = useRegionPageStore();
  const navigationStore = useSportlineNavigationStore();
  const containEvents = toRef(regionPageStore, 'containEvents');
  const region = toRef(regionPageStore, 'region');
  const baseRegionNavigationGuard = useBaseSportEventsRegionPageNavigationGuard();
  const sportlineNavigationBetweenPagesFilter = toRef(navigationStore, 'sportlineNavigationBetweenPagesFilter');

  const {
    prefetch: basePrefetch,
  } = baseRegionNavigationGuard;

  function prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AbstractPrefetchResult {
    const { regionConfig } = useLoadRegionConfig({
      to: toRef(to),
      sportlineNavigationBetweenPagesFilter,
    });

    const superResult = basePrefetch(router, to, from);

    if (superResult !== undefined) {
      return (superResult as RouteLocationRaw);
    }

    // LEONWEB-3297 handle 301 redirect broken url
    if (getIsBrokenUrl(to)) {
      return router.resolve301location({ name: RouteName.HOME });
    }

    void regionPageStore.awaitStateIsLoaded().then(() => {
      if (!containEvents.value && !region.value) {
        // @TODO split 301 and 302 redirects for just empty events and corrupted regions
        return router.resolve302location({ name: RouteName.HOME });
      }
    });
    regionPageStore.setConfigForRegionLoading(regionConfig.value);
    void regionPageStore.initialRequests();
  }

  return { prefetch };
}
